
function formatTime(date) {
	var year = date.getFullYear();
	var month = date.getMonth() + 1;
	var day = date.getDate();

	var hour = date.getHours();
	var minute = date.getMinutes();
	var second = date.getSeconds();


	return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

function formatDate(date) {
	if(!date){
		date = new Date()
	}
	var year = date.getFullYear();
	var month = date.getMonth() + 1;
	var day = date.getDate();


	return [year, month, day].map(formatNumber).join('-')
}

function formatNumber(n) {
	n = n.toString();
	return n[1] ? n : '0' + n
}

const getParentAreaByValue = (list,id) => {
	for (let i in list) {
		if (list[i].value === id) {

			//查询到就返回该数组对象
			return [list[i]]
		}

		if (list[i].children) {

			let node = getParentAreaByValue(list[i].children, id)
			if (node !== undefined) {
				//查询到把父节点连起来
				return node.concat(list[i])
			}
		}
	}
}
let getBase64Sync = (imgUrl) => {
	return new Promise(function (resolve, reject) {
		// 一定要设置为let，不然图片不显示
		let image = new Image();
		// 解决跨域问题
		image.crossOrigin = 'anonymous';
		//图片地址
		image.src = imgUrl;
		// image.onload为异步加载
		image.onload = function () {
			let canvas = document.createElement("canvas");
			canvas.width = image.width;
			canvas.height = image.height;
			let context = canvas.getContext("2d");
			context.drawImage(image, 0, 0, image.width, image.height);
			//图片后缀名
			let ext = image.src.substring(image.src.lastIndexOf(".") + 1).toLowerCase();
			//图片质量
			let quality = 0.8;
			//转成base64
			let dataurl = canvas.toDataURL("image/" + ext, quality);
			//返回
			resolve(dataurl);
		};
	})
}
export default {
    formatTime,
    formatDate,
	getParentAreaByValue,
	getBase64Sync
}